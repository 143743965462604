@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Montserrat:wght@400;500;600&family=Oswald:wght@300;400;500&family=Rajdhani:wght@400;500;600&family=Roboto:wght@400;500;700&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
html {
  height: 100%;
  width: 100%;
}
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  background-color: #ffffff;
}

.cursor-pointer {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize
}

$sizes: 100;

@mixin size-classes {
  @for $i from 1 through $sizes {
     $size: $i * 0.25rem;
    .h#{$i}  {height: $size;}
    .w#{$i} {width: $size;}
  }
}
@include size-classes;

@mixin margin-classes {
  @for $i from 1 through $sizes {
     $margin: $i * 0.25rem;
    /* margin #{$margin} */
    .m#{$i}  {margin: $margin;}
    .ml#{$i} {margin-left: $margin;}
    .mr#{$i} {margin-right: $margin;}
    .mt#{$i} {margin-top: $margin;}
    .mb#{$i} {margin-bottom: $margin;}
    .mx#{$i} {margin-left: $margin; margin-right: $margin;}
    .my#{$i} {margin-top: $margin; margin-bottom: $margin;}
  }
}
@include margin-classes;


@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 0.25rem;
    /* padding #{$padding} */
    .p#{$i} {padding: $padding;}
    .pl#{$i} {padding-left: $padding;}
    .pr#{$i} {padding-right: $padding;}
    .pt#{$i} {padding-top: $padding;}
    .pb#{$i} {padding-bottom: $padding;}
    .px#{$i} {padding-left: $padding; padding-right: $padding;}
    .py#{$i} {padding-top: $padding; padding-bottom: $padding;}
  }
}
@include padding-classes;


/* Custom CSS */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.error-txt {
  color: #d32f2f !important;
}
.success-txt {
  color: #388e3c !important;
}

.percentage-down-txt {
  color: #d32f2f !important;
  background-color: rgb(252,232,230) !important;
}

.percentage-up-txt {
  color: #388e3c !important;
  background-color: rgb(230,244,234) !important;
}

header {
  background-color: #ffffff;
  padding-right: 0px !important;
}

.loader {
  top: 50%;
  left: 50%;
  position: absolute;
}

td, th {
  padding: 4px 8px !important;
}
tr {
  height: 40px !important;
}

.justify-content-center {
  justify-content: center !important;
}